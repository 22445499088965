var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "approval-opinion-dialog",
      attrs: {
        title: "常用批复意见",
        modal: true,
        "append-to-body": "",
        "modal-append-to-body": false,
        "close-on-click-modal": false,
        visible: _vm.approvalOpinionDialog,
        width: "45%",
        top: "9vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.approvalOpinionDialog = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "常用批复意见",
                  "head-btn-options": _vm.gridHeadBtn,
                },
                on: { "head-add": _vm.headAdd, "head-delete": _vm.headRemove },
              }),
              _c("grid-layout", {
                ref: "gridLayout",
                staticClass: "approvalCrud",
                attrs: {
                  "head-title": _vm.$t(
                    "cip.plat.sys.user.title.indexHeadTitle"
                  ),
                  gridRowBtn: _vm.gridRowBtn,
                  tableOptions: _vm.tableOptions,
                  tableData: _vm.tableData,
                  tableLoading: _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "page-size-change": _vm.onLoad,
                  "page-current-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.selectionChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return [
                        !row.$cellEdit
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowEdit(row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        !row.$cellEdit
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowRemove(row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                        row.$cellEdit
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowSave(row)
                                  },
                                },
                              },
                              [_vm._v("保存")]
                            )
                          : _vm._e(),
                        row.$cellEdit
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowCancel(row)
                                  },
                                },
                              },
                              [_vm._v("取消")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }