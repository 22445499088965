var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      !_vm.hideApproval ? _c("div", { staticClass: "topGrey" }) : _vm._e(),
      _c("avue-form", {
        ref: "examineForm",
        attrs: { option: _vm.examineOption },
        model: {
          value: _vm.examineForm,
          callback: function ($$v) {
            _vm.examineForm = $$v
          },
          expression: "examineForm",
        },
      }),
      !_vm.hideApproval
        ? _c(
            "el-popover",
            {
              ref: "popover",
              attrs: {
                width: "400",
                trigger: "manual",
                width: _vm.popoverWidth,
              },
              on: { show: _vm.openPopover, hide: _vm.closePopover },
              model: {
                value: _vm.popoverShow,
                callback: function ($$v) {
                  _vm.popoverShow = $$v
                },
                expression: "popoverShow",
              },
            },
            [
              _c(
                "div",
                { staticClass: "selectContent" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容" },
                    on: { input: _vm.inputSearch },
                    model: {
                      value: _vm.searchValue,
                      callback: function ($$v) {
                        _vm.searchValue = $$v
                      },
                      expression: "searchValue",
                    },
                  }),
                  _vm._l(_vm.selectModelList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "selectModel",
                        style: {
                          color:
                            _vm.selectActive == index ? _vm.themeColor : "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.chooseSelct(item, index)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.name) + "\n        "
                        ),
                      ]
                    )
                  }),
                  _c("div", { staticClass: "line" }),
                  _c("el-button", {
                    staticClass: "addBtn",
                    attrs: {
                      type: "primary",
                      icon: "el-icon-circle-plus-outline",
                    },
                    on: { click: _vm.addMore },
                  }),
                ],
                2
              ),
              _c(
                "el-button",
                {
                  staticClass: "approvalBtn",
                  staticStyle: { width: "100%", "text-align": "left" },
                  attrs: { slot: "reference", disabled: _vm.type == "view" },
                  on: {
                    click: function ($event) {
                      _vm.popoverShow = true
                    },
                  },
                  slot: "reference",
                },
                [_vm._v(_vm._s(_vm.popoverText))]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("approval-opinion-dialog", {
        ref: "approvalOpinionDialog",
        on: { closeDialog: _vm.getOpinionList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }