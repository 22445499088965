<template>
  <el-dialog
    title="常用批复意见"
    :modal="true"
    append-to-body
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :visible.sync="approvalOpinionDialog"
    width="45%"
    top="9vh"
    class="approval-opinion-dialog"
    @close="closeDialog"
  >
    <el-container>
      <el-main>
        <head-layout head-title="常用批复意见"
                     :head-btn-options="gridHeadBtn"
                     @head-add="headAdd"
                     @head-delete="headRemove">
        </head-layout>
        <grid-layout
          class="approvalCrud"
          ref="gridLayout"
          :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
          :gridRowBtn="gridRowBtn"
          :tableOptions="tableOptions"
          :tableData="tableData"
          :tableLoading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-size-change="onLoad"
          @page-current-change="onLoad"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange"
        >
          <template #customBtn="{ row }">
            <el-button v-if="!row.$cellEdit" type="text" size="small" @click="rowEdit(row)">编辑</el-button>
            <el-button v-if="!row.$cellEdit" type="text" size="small" @click="rowRemove(row)">删除</el-button>
            <el-button v-if="row.$cellEdit" type="text" size="small" @click="rowSave(row)">保存</el-button>
            <el-button v-if="row.$cellEdit" type="text" size="small" @click="rowCancel(row)">取消</el-button>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
  </el-dialog>
</template>

<script>
import {getPage,submit,remove} from "@/api/plugin/workflow/process/approval-opinion-api";
import GridLayout from "@/views/components/layout/grid-layout";
import {mapGetters} from "vuex";

export default {
  name: "approvalOpinionDialog",
  components: {
    GridLayout,
  },
  data() {
    return {
      query: {},
      selectionList: [],
      approvalOpinionDialog: false,
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableOptions: {
        menu: true,
        menuWidth: 120,
        selection: true,
        column: [
          {
            label: "审批意见",
            prop: "name",
            cell: true,
            overHidden: true,
            maxlength: 200,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入审批意见",
                trigger: "blur"
              }
            ],
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters(['userInfo']),
    ids() {
      let ids = [];
      this.selectionList
        .filter((e) => e.id)
        .forEach((ele) => {
          ids.push(ele.id);
        });
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          label: "",
          prop: "name",
          span: 8,
          placeholder: "请输入名称",
        },
      ];
    },
    gridHeadBtn() {
      let result = [];
      result.push({
        label: this.$t(`cip.cmn.btn.addBtn`),
        emit: "head-add",
        type: "button",
      })
      result.push({
        label: this.$t(`cip.cmn.btn.delBtn`),
        emit: "head-delete",
        type: "danger",
      })
      return result;
    },
    // 行按钮添加
    gridRowBtn() {
      return []
    },
  },

  methods: {
    headAdd (index) {
      this.tableData.splice(index, 0, {
        $cellEdit: true
      })
    },
    headRemove() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("确定将选择数据删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          remove(this.ids).then(() => {
            this.onLoad(this.page, {});
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.selectionClear();
          });
        })
        .catch(() => {});
    },
    rowEdit(row) {
      if (!row.$cellEdit) {
        this.$refs.gridLayout.getGrid().rowCell(row, row.$index);
      }
    },
    rowRemove(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        this.tableLoading = true;
        remove(row.id).then(() => {
            this.onLoad(this.page, {});
          })
          .catch(() => {
            this.tableLoading = false;
          });
      })
    },
    rowSave(row) {
      if (!row.name || row.name === ""){
        this.$message.warning("请输入审批意见");
        return;
      }
      submit(row).then((res) => {
        this.tableData[row.$index].$cellEdit = false;
        this.$set(this.tableData, row.$index, res.data.data);
        this.$message({
          type: "success",
          message: this.$t("cip.cmn.msg.success.operateSuccess"),
        });
      });
    },
    rowCancel(row) {
      if (row.$cellEdit) {
        this.$refs.gridLayout.getGrid().rowCancel(row, row.$index);
      }
    },
    openDialog() {
      this.approvalOpinionDialog = true;
      this.onLoad(this.page);
    },
    closeDialog() {
      this.approvalOpinionDialog = false;
      this.$emit('closeDialog')
    },
    selectionClear() {
      this.selectionList = [];
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      // 查询个人的审批意见
      if (this.userInfo.user_id !== undefined) {
        Object.assign(params, {createUser: this.userInfo.user_id})
      }
      getPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayout.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
  },
}
</script>

<style scoped lang='scss'>

.approval-opinion-dialog {
  ::v-deep .el-dialog__body {
    padding: 0 20px !important;
  }
}

::v-deep .approvalCrud .avue-crud .el-table {
  height: calc(100vh - 374px) !important;
  max-height: calc(100vh - 374px) !important;
}

// 流程详情不随主题色改变
::v-deep .el-button--text {
  color: #409EFF;
}

::v-deep .approvalCrud .el-button:hover {
  color: #66b1ff !important;
  border-color: transparent !important;
  background-color: transparent !important;
}

::v-deep .el-input__inner:focus {
  border-color: #409EFF;
}

::v-deep .el-checkbox__inner:hover {
  border-color: #409EFF;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #409EFF;
  border-color: #409EFF;
}

::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #409EFF;
  border-color: #409EFF;
}

::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #409EFF;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #409EFF;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #FFFFFF;
  background-color: #409EFF;
}

::v-deep .el-loading-spinner .path {
  stroke: #409EFF;
}

::v-deep .el-select .el-input.is-focus .el-input__inner {
  border-color: #409EFF;
}

::v-deep .el-pagination__sizes .el-input .el-input__inner:hover {
  border-color: #409EFF;
}
</style>
<style>
.el-select-dropdown__item.selected {
  color: #409EFF !important;
}

.el-dialog__headerbtn:hover .el-dialog__close {
  color: #409EFF !important;
}
</style>
